import React, { useRef, useEffect, useContext } from "react";
import { Container, Row, Button } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import DropdownTest from "../../shared/DropdownTest";
import tours from "../../assets/data/tours"
import "./header.css";
import { AuthContext } from "./../../context/AuthContext";
import { FaTripadvisor } from 'react-icons/fa';

const nav__links = [
  {
    path: "/home",
    display: "Home",
  },

  {
    path: "/tours",
    display: "Tours",
  },

  {
    path: "/day",
    display: "Day Trip",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/contact",
    display: "Contact Us",
  },

];

const custumIcone = {
  fontSize: '28px',
  marginRight: '9px',

}

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const history = useHistory();
  const { user, dispatch } = useContext(AuthContext);

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();

    return window.removeEventListener("scroll", stickyHeaderFunc);
  });

  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav__wrapper d-flex align-items-center justify-content-between">
            {/* =========== logo ============ */}
            <div className="logo1">
              <Link to="/home">
                <img src={logo} alt="Morocco  Happinesse tours" />

              </Link>
            </div>
            {/* =========== logo  end============ */}

            {/* ===========menu start============ */}
            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <ul className="menu d-flex align-items-center gap-5">
                {nav__links.map((item, index) => (
                  <li className="nav__item" key={index}>
                    <NavLink
                      to={item.path}
                      className={navClass =>
                        navClass.isActive ? "active__link" : ""
                      }
                    >
                      {item.display}
                    </NavLink>
                  </li>

                ))}
                

              </ul>




            </div>
            {/* ===========menu end============ */}

            <div className="nav__right d-flex align-items-center gap-4 ">
              <div className="nav__btns d-flex align-items-center gap-4 ">
                {user ? (
                  <>
                    <h5 className="mb-0">{user.username}</h5>
                    <Button className="btn btn-dark" onClick={logout}>
                      Logout
                    </Button>
                  </>
                ) : (
                  <>



                    <div className="btn tripAdvisor_btn " >
                      <a href="https://www.tripadvisor.com/Attraction_Review-g293732-d14179822-Reviews-Morocco_Happiness_Tours-Casablanca_Grand_Casablanca_Region.html" target="_blank" title="Tripadvisor">
                        <FaTripadvisor style={custumIcone} />
                        TripAdvisor
                      </a>
                    </div>
                  </>
                )}
              </div>

              <span className="mobile__menu" onClick={toggleMenu}>
                <i className="ri-menu-line"></i>
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
