import React, { useEffect, useRef, useState, useContext } from "react";
import "../styles/tour-details.css";
import { Container, Row, Col, Form, ListGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import calculateAvgRating from "./../utils/avgRating";
import avatar from "../assets/images/avatar.jpg";
import Booking from "../components/Booking/Booking";
import config from "./../utils/config";
import { AuthContext } from "./../context/AuthContext";
//import ImageSlider2 from "../shared/ImageSlider2";
import ImageSlider from "../shared/ImageSlider";

import tourData from "../assets/data/tours"
import { DescriptionOutlined } from "@mui/icons-material";

const TourDetails = () => {
  const { id } = useParams();
  const tour = tourData.find(tour => tour.id == id)

  const reviewMsgRef = useRef("");
  //const [tour, setTour] = useState(null);
  const [tourRating, setTourRating] = useState(null);
  const { user } = useContext(AuthContext);
/*
  console.log("ID:", id);

  useEffect(() => {
    const fetchTourData = async () => {
      try {
        const response = await config(`/programs/${id}`);
        const tourData = await response
        console.log("Tour Data:", tourData);
        
        setTour(tourData)
        // Mettez à jour l'état ou effectuez d'autres actions avec les données de la visite.
      } catch (error) {
        console.error("Error fetching tour data:", error);
      }
    };

    fetchTourData();
  }, [id]);

  if (!tour) {
    return <p>La visite avec l'ID {id} n'a pas pu être trouvée.</p>;
  }*/

  const {
   // picture,
    title,
    photo,
    desc,
   // description,
   // reviews,
    cityFrom,
    Highlights,
   // day,
   Day,
    type,
    cityTO,
    
  } = tour;

  //const imageUrls = tour.picture.map((image) => `http://localhost:3001/images/${image.url}`);

//console.log(imageUrls)
  //const { totalRating, avgRating } = calculateAvgRating(reviews);
  const options = { day: "numeric", month: "long", year: "numeric" };

  const submitHandler = async (e) => {
    e.preventDefault();
    const reviewText = reviewMsgRef.current.value;
    // Envoyer la revue au serveur ici
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col lg="8">
              <div className="tour__content">
                <ImageSlider images={photo} />

                <div className="tour_info">
                  <h2>{title}</h2>

                  <div className="d-flex align-items-center gap-5">
                    <span className="tour__rating d-flex align-items-center gap-1">
                      { <i
                        className="ri-star-s-fill"
                        style={{ color: "var(--secondary-color)" }}
                      ></i>
                      }
                    </span>
                  </div>
                  

                  <div className="tour__extra-details">
                    <span>
                      <i className="ri-map-pin-2-line"></i> {cityFrom}
                    </span>
                    <span><i className="ri-arrow-right-fill"></i></span> 
                   
                    <span>
                      <i className="ri-map-pin-2-line"></i> {cityTO}
                    </span>
                    <br /> 
                  
                  </div>
                  <div className="tour__extra-details">
                  <span>
                      <i className="ri-calendar-event-fill"></i> {Day.length} Day
                    </span>
                    </div>

                </div>

                <div className="tour_info mt-4">
                  <h4>Highlights</h4>
                  <ul>
               
                    {Highlights.map((highlight, index) => (
                     <li key={index}>
                     <i className="ri-bubble-chart-fill" 
                     style={{ color: "var(--secondary-color)" }}
                     ></i> {highlight.title}
                  
                 
                   </li>
                   ))}
                  </ul>
                </div>
                
                <div className="tour_info mt-4">
                  <div className="listing-section">
                    <h2>
                      <i className="ri-menu-unfold-fill"></i> PROGRAM DETAILS
                    </h2>
                    {Day.map((day, index) => (
                      <div key={index}>
                        <h3>
                          <span>{index + 1}</span> {day.title}
                        </h3>
                        <p> {day.desc}</p>
                      </div>
                    ))}
                  </div>
                </div>

               
                
              </div>
            </Col>

           { <Col lg="4">
              <Booking tour={tour} /*avgRating={avgRating}*/ />
            </Col>}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TourDetails;
