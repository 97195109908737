import React, { useRef, useState } from "react";
import "./newsletter.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Form, Label, Button, Input } from "reactstrap";
import maleTourist from "../assets/images/male-tourist.png";
import tourData from "../assets/data/tours";
import emailjs from "emailjs-com";
import { RiUser2Fill, RiEarthFill } from 'react-icons/ri';

const Newsletter = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
       emailjs
       .sendForm(
        'service_57x19j5', 
        'template_tcjckyk', 
        form.current,
         'x9r8_fY2CRt6sDtcg')

      .then((result) => {
        console.log(result.text);
        toast.success("Message sent successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        form.current.reset();
        setIsSuccess(true);
      })
      .catch((error) => {
        toast.error("Failed to send message. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setErrorMessage("Failed to send message. Please try again later.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <section className="newsletter">
      <Container>
        <Row>
          <Col lg="6">
            <div className="newsletter__img">
              <img src={maleTourist} alt="" />
            </div>
          </Col>

          <Col lg="6">
            <div className="newsletter__content">
              <h2>
                Book Now and Embark on an Unforgettable Journey in Beautiful
                Morocco!
              </h2 >
              <h4 className="newsletter__p ">
                If you didn't find any tour as you like, don't be afraid we can
                create a tour for you!
              </h4>

              <div className="newsletter__input">
                <ToastContainer />
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                  <Col sm="12" md="6">
                      <div className="from-wraper">
                        <i className="ri-user-2-fill"></i>
                        <Input
                          id="exampleName"
                          name="user_name"
                          placeholder="Your Name"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="from-wraper">
                        <i className="ri-earth-fill"></i>
                        <Input
                          id="exampleCountry"
                          name="country"
                          placeholder="Your Country"
                          type="text"
                        />
                      </div>
                    </Col>
                   
                    <Col sm="12" md="6">
                      <div className="from-wraper">
                        <i className="ri-mail-send-line"></i>
                        <Input
                          id="exampleEmail"
                          name="user_email"
                          placeholder="Your email"
                          type="email"
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="6">
                      <div className="from-wraper">
                        <i className="ri-phone-line"></i>
                        <Input
                          id="examplePhone"
                          name="user_num"
                          placeholder="Your phone"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="from-wraper">
                        <i className="ri-calendar-2-line"></i>
                        <Input
                          id="message"
                          name="message"
                          placeholder="Tell us what you need"
                          type="textarea"
                        />
                      </div>
                    </Col>
                    <Col sm="12">
                      <Button type="submit" className="btn newsletter__btn">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
