import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from  "../pages/Home.jsx"
import Tours from "./../pages/Tours";
import TourDetails from "./../pages/TourDetails";
import About from "../pages/About";
import SearchResultList from "./../pages/SearchResultList";
import ThankYou from "../pages/ThankYou";
import Activite from "../pages/Activite";
import Contact from "../pages/Contact";
import MasonryImageGallery from "../components/Image-gallery/MasonryImageGallery";
import DayTour from "../pages/DayTour";
import Cities from "../pages/cities/Cities";
import Programs from "../pages/programs/Programs";
import Types from "../pages/types/Types";
import Highlights from "../pages/highlights/Highlights";
import Pictures from "../pages/pictures/Pictures";
import AddDay from "../pages/day/AddDay";
import AddHighlight from "../pages/highlights/Addhighlight";
import AddPicture from "../pages/pictures/AddPicture";
import AddProgram from "../pages/programs/AddProgram";
import EditDay from "../pages/day/EditDay";
import EditHighlight from "../pages/highlights/EditHighlight";
import EditPicture from "../pages/pictures/EditPicture";
import EditProgram from "../pages/programs/EditProgram";
import Days from "../pages/day/Days";
import Logout from "../pages/login/Logout";
import Login from "../pages/login/Login";
import HomeAdmin from "../pages/home/Home.jsx"
import PrivateRoute from "../pages/PrivateRoute";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer";
const isAdminRoute = (path) => {
  return path.includes("Admin"); 
};
const Routers = (...rest) => {
  
  return (
    <>
    

   
    {isAdminRoute(window.location.pathname) ? null : <Header />}
      <Route path="/home" component={Home } />
      <Route path="/tours" component={Tours } />
      <Route path="/toursdetails/:id" component={TourDetails} />
      <Route path="/about" component={About} />
      <Route path="/gallery" component={MasonryImageGallery } />
      <Route path="/thank-you" component={ThankYou } />
      <Route path="/search" component={SearchResultList } />
      <Route path="/activite" component={Activite } />
      <Route path="/contact" component={Contact } />
      <Route path="/day" component={DayTour } />

     

      
     <Redirect from='/' to='/home'/>
   
      {isAdminRoute(window.location.pathname) ? null : <Footer />}


   {/* <Route path="/loginAdmin" component={Login}/>
      <PrivateRoute path="/homeAdmin" component={HomeAdmin} />
      <PrivateRoute path="/citiesAdmin" component={Cities} />
      <PrivateRoute path="/typesAdmin" component={Types} />
      <PrivateRoute path="/programsAdmin" component={Programs} />
      <PrivateRoute path="/add-program" component={AddProgram} />
      <PrivateRoute path="/edit-program/:id" component={EditProgram} />

      <PrivateRoute path="/daysAdmin" component={Days} />
      <PrivateRoute path="/add-day" component={AddDay} />
      <PrivateRoute path="/edit-day/:id" component={EditDay} />

      <PrivateRoute path="/highlightsAdmin" component={Highlights} />
      <PrivateRoute path="/add-highlight" component={AddHighlight} />
      <PrivateRoute path="/edit-highlight/:id" component={EditHighlight} />

      <PrivateRoute path="/pic-by-prog" component={Pictures} />
      <PrivateRoute path="/add-picture" component={AddPicture} />
      <PrivateRoute path="/edit-picture/:id" component={EditPicture} />

      <PrivateRoute path="/logout"  render={(props) => <Logout {...props} />} />*/}
    
    

    </>
  );
};

export default Routers;
