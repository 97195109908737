import React, { useState } from "react";
import { useEffect } from 'react';
import ImageSlider from "../shared/ImageSlider";
import { Container, Row, Col } from "reactstrap";
import config from "../utils/config";
import { useLocation } from "react-router-dom";
import TourCard from "./../shared/TourCard";
import tourData from "../assets/data/tours"

import Newsletter from "./../shared/Newsletter";

const SearchResultList = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const city = params.get('city');
  const dayv = params.get('day');
 // Initialiser programs avec un tableau vide
  // Filter tours based on city and exact number of days
  // const data = tourData.filter(tour => tour.cityFrom === city && tour.Day.length === Number(day));
  
  /*const [Tours, setDayTours] = useState([]);

  const fetchToursByCityAndDay = async () => {
      try {
          const response = await config("/programs");
          // console.log("response",response);
          const data = await response;
           setDayTours(data);
           console.log(Tours.title)
      } catch (error) {
          console.log(error);
      }
  };

  useEffect(() => {
      fetchToursByCityAndDay();
  }, []);

  const data = Tours.filter((program) => {
    return (
      (city === "" || program.cityTo.name === city || program.cityFrom.name === city) &&
      (isNaN(dayv) || (program.day && program.day.length === Number(dayv)))
    );
  });*/
  const data = tourData.filter(tour => tour.cityFrom === city && tour.Day.length === Number(dayv));


  
  const alternativeTours = tourData.filter((program) => {
    return (
      (city === "" || program.cityT === city || city === "" || program.cityFrom=== city) &&
      (isNaN(dayv) || (!program.day || program.day.length !== Number(dayv)))
    );
  });
  

  
  return (
    <>
      <section>
        <Container>
          <Row>
            {data.length === 0 ? (
              <>
                <h4 className="text-center">No tour found with {dayv} days in {city}</h4>
                <h4 className="text-center">We can create a private tour for you, please contact us.</h4>
                {alternativeTours.map(tour => (
                  <Col lg="3" className="mb-4" key={tour._id}>
                    <TourCard tour={tour} />
                  </Col>
                ))}
              </>
            ) : (
              data.map(tour => (
                <Col lg="3" className="mb-4" key={tour._id}>
                  <TourCard tour={tour} />
                </Col>
              ))
            )}
          </Row>
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

export default SearchResultList;
