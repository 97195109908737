import "./App.css";
import Layout from "./components/Layout/Layout";
import Routes from "./pages/Routes";
import { DarkModeContext } from "./context/darkModeContext";
import { useContext } from "react";
import "./styles/dark.scss";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Routers from "./router/Routers";
import AdminRouter from "./router/AdminRouter";
import Login from "./pages/login/Login";
function App() {
  const { darkMode } = useContext(DarkModeContext);

  return(
    <div className={darkMode ? "app dark" : "app"}>
    <Router>
    <Layout/>
    </Router>


   
      
      
      
        
  
      </div>


  ) 
}

export default App;
