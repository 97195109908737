import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "../styles/contact.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import sahara from "../assets/images/sahara.jpg";
import tagine from "../assets/images/tagine.jpg";
import weather from "../assets/images/weather.png";
import ImageSlider from "../shared/ImageSlider";
import { Container } from "reactstrap";
import Newsletter from "../shared/Newsletter";

export const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" lang="eng" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>Contact Us </title>
        <meta
          name="description"
          content="Contact Us : We are a small independent company in Morocco. We believe the best way for customers to experience the authentic Moroccan culture is through the local people and therefore we emphasize on offering only certified local guides."
        />
        <meta
          name="keywords"
          content="Activities In Marrakech, Trips From Marrakech, Tours From Marrakech, Tours From Fez,  Tours From Casablanca, Day Tour To Imlil And Toubkal In High Atlas Mountains From Marrakech, Day Tour To 3 Valleys And Atlas Mountains Trip From Marrakech, Day Tour To Ourika Valley And The High Atlas Mountains From Marrakech, Day Tour To Ouarzazate And Ait Ben Haddou Old Kasbahs From Marrakech, Day Tour To Essaouira The Ancient Mogador City And Coast From Marrakech, Day Tour To Ouzoud Waterfalls & The Middle Atlas Mountain From Marrakech, 2 Days Trip From Marrakech To Zagora Desert Sahara, 3 Days Tour To Merzouga Desert Via Atlas Mountain, 3 Days Tour From Marrakech Via The Sahara Desert To Fez, 4 Days Tour From Marrakesh Through Merzouga Desert To Fez, 4 Days tour from Marrakech to Marrakech via Merzouga desert, 7 Days Tour From Marrakech Via Desert And Imperial Cities, 2 Days Tour From Fes To Explore The Chefchaouen City, 3 Days From Fes To Marrakech Tour Via Erg Chebbi Dunes, 3 Days Desert Tour From Fes To Merzouga And Back To Fes, 4 Days Tour From Fes To Marrakech Via Merzouga Desert, 5 Days Tour From Fez Via Merzouga Desert To Marrakech, 6 Days Tour From Fes To Modern Cities In Northern Morocco"
        />
        <meta
          name="apple-mobile-web-app-title"
          content="Morocco Happiness Tours"
        />
        <link
          rel="apple-touch-icon-precomposed"
          href="images/apple-touch-icon.png"
          title="Morocco Happiness Tours"
        />
        <link
          rel="apple-touch-icon"
          href="images/apple-touch-icon.png"
          title="Morocco Happiness Tours"
        />
        <link
          rel="shortcut icon"
          href="images/favicon.png"
          type="image/x-icon"
        />
        <link
          rel="canonical"
          href="https://www.moroccohappinesstours.com/contact"
        />
        <meta name="og:site_name" content="Morocco Happiness Tours" />
        <meta
          name="og:title"
          content="Morocco Sahara Desert Tours, Tours From Marrakech"
        />
        <meta name="og:url" content="https://www.moroccohappinesstours.com" />
        <meta
          name="og:image"
          content="https://www.moroccohappinesstours.com/img/logo.png"
        />
        <meta name="og:type" content="Travel" />
        <meta name="og:locality" content="Casablanca" />
        <meta name="og:description" content="Desert Tours from Marrakech" />
        <link rel="stylesheet" href="css/style.css" />
        <link rel="stylesheet" href="css/main.css" />
      </Helmet>
      <Newsletter />
    </>
  );
};

export default Contact;
