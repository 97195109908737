import React from "react";
import ReactDOM from "react-dom/client";
import {HelmetProvider} from "react-helmet-async"
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { DarkModeContextProvider } from "./context/darkModeContext"; // Corrected import path
import { TokenProvider } from "./pages/login/TokenContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
      <HelmetProvider>
      <DarkModeContextProvider>
      <TokenProvider>
        <App />
        </TokenProvider>
    </DarkModeContextProvider>
        </HelmetProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>
);
