import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import calculateAvgRating from "../utils/avgRating";
import { FaTripadvisor } from 'react-icons/fa';

import "./tour-card.css";

const TourCard = ({ tour }) => {
  const { id, title, cityFrom, Day, reviews, picture,image } = tour;
  const custumIcone={
    fontSize:'20px',
    marginRight:'9px',
  
  }
  
 /*const { totalRating, avgRating } = calculateAvgRating(reviews);
  let imageUrl = "";
  if (tour.picture && tour.picture.length > 0) {
    imageUrl = `http://localhost:3001/images/${tour.picture[0].url}`;
  }*/
  //let imageDescription = tour.picture[0].description;
  return (
    <div className="tour__card">
      <Card>
        <div className="tour__img">
          <img src={image} alt="Morocco Tours" />
          {<span>{Day.length} Day</span>}
        </div>

        <CardBody>
          <div className="card__top d-flex align-items-center justify-content-between">
            <span className="tour__location d-flex align-items-center gap-1">
              <i className="ri-map-pin-line"></i> {cityFrom}
            </span>
          </div>
          <div className="tour__title_height">
            <h5 className="tour__title">
              <Link to={`/tours/${id}`}>{title} </Link>
            </h5>

          </div>


          <div className="card__bottom d-flex align-items-center justify-content-between mt-3">
            <h5>
              <span>
                 <FaTripadvisor style={custumIcone} />
              </span>
            </h5>

            <button className="btn booking__btn">
              <Link to={`/toursdetails/${id}`}>Book Now</Link>
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TourCard;
