import React, { useState, useEffect } from "react";
import ImageSlider from "../shared/ImageSlider";
import "../styles/tour.css";
import TourCard from "./../shared/TourCard";
import SearchBar from "./../shared/SearchBar";
import Newsletter from "./../shared/Newsletter";
import { Helmet } from "react-helmet-async";
import config from "../utils/config";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container } from "reactstrap";
import classnames from 'classnames';
import tourData from "../assets/data/tours"



import img from "../assets/images/ouzoud.jpg";
import img2 from "../assets/images/toubkal.jpg";
import img3 from "../assets/images/valley.jpg"
import img4 from "../assets/images/essaouira.jpg"

const DayTour = () => {
    <Helmet>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

        <title>Day Tours with Morocco happiness tours</title>
        <meta name="description" content="Day Tour To Ouarzazate ,Day Tours,Ait Ben Haddou Old Kasbahs , Marrakech, Morocco Trip From Marrakech,Morocco Trip, Trip Starting From Marrakech, Trip From Marrakech " />
        <meta name="keywords" content="Day Tour To Ouarzazate, Ait Ben Haddou Old Kasbahs ,Marrakech, Desert Trip From Marrakech, Sahara Trip From Marrakech, Morocco Trip From Marrakech, Trips Starting From Marrakech, Trips From Marrakech, Trips to Desert From Marrakech,Trip,Morocco,Marrakech,Essaouira,Imlil,Toubkal" />
        <link
            rel="canonical"
            href="https://www.moroccohappinesstours.com/day"
        />
        <meta name="apple-mobile-web-app-title" content="Morocco Happiness Tours" />

        <meta name="og:site_name" content="Morocco Happiness Tours" />
        <meta name="og:title" content="Morocco Sahara Desert Tours, Tours From Marrakech" />
        <meta name="og:url" content="https://www.moroccohappinesstours.com" />
        <meta name="og:image" content="https://www.moroccohappinesstours.com/img/logo.png" />
        <meta name="og:type" content="Travel" />
        <meta name="og:locality" content="Casablanca" />
        <meta name="og:description" content="Tours from Marrakech" />


    </Helmet>



    const [activeTab, setActiveTab] = useState('1');
    const [currentPage, setCurrentPage] = useState(1);
    const [toursPerPage] = useState(12); // Number of tours to display per page

    /*const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);*/


    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setCurrentPage(1); // Reset current page when changing tabs
        }
    };
   
   
    //const [Tours, setDayTours] = useState([]);
    
    /*const fetchDayTours = async () => {
        try {
            const response = await config("/programs");
          
            const dayTour = await response;
             setDayTours(dayTour);
              setLoading(false);
        } catch (error) {
            console.log(error);
              setError("Une erreur s'est produite lors du chargement des données."); // Définir l'erreur
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDayTours();
    }, []);
*/

   /* const filterToursByType = (tourData, type) => {
        return tourData.filter(tour => tour.type === type);
    };

    // Filtrer les tours par type
   // const dayTours = filterToursByType(tourData, "Day Trips");*/

      //////////////////////////////
      const dayTours = tourData.filter(tour =>
        tour.type.some(type => type.name === "Day Trips")
      );
      const citiesToToursMap = {};

      /*dayTours.forEach(tour => {
        const cityName = tour.cityFrom; // Obtenez le nom de la ville
        if (!citiesToToursMap[cityName]) {
            citiesToToursMap[cityName] = [];
        }
        citiesToToursMap[cityName].push(tour);
    });*/
    dayTours.forEach(tour => {
        if (!citiesToToursMap[tour.cityFrom]) {
            citiesToToursMap[tour.cityFrom] = [];
        }
        citiesToToursMap[tour.cityFrom].push(tour);
    });
    const uniqueCities = Object.keys(citiesToToursMap);

    const indexOfLastTour = currentPage * toursPerPage;
    const indexOfFirstTour = indexOfLastTour - toursPerPage;

    const paginate = pageNumber => setCurrentPage(pageNumber);


    



    const DayImages = [
        { url: img },
        { url: img2 },
        { url: img3 },
        { url: img4 },


    ];

    return (
        <>
            <Container>
                <ImageSlider images={DayImages} />
            </Container>

            {/*} {loading && <h4 className="text-center pt-5">Loading........</h4>}
            {error && <h4 className="text-center pt-5">{error}</h4>}
            {!loading && !error && (*/}
            <Container>
                <section>
                    <Nav className="mb-4" tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => toggleTab('1')}
                            >
                                Top Trip
                            </NavLink>
                        </NavItem>
                        {uniqueCities.map((city, index) => (
                            <NavItem key={index}>
                                <NavLink
                                    className={classnames({ active: activeTab === index })}
                                    onClick={() => toggleTab(index)}
                                >
                                    {city}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        {activeTab === '1' && (
                            <TabPane tabId="1">
                                <Row>
                                    {dayTours.slice(indexOfFirstTour, indexOfLastTour)
                                        .map(tour => (
                                            <Col lg="3" md="3" sm="1" className="mb-4" key={tour.id}>
                                                <TourCard tour={tour} />
                                            </Col>
                                        ))}
                                </Row>
                                <Pagination
                                    toursPerPage={toursPerPage}
                                    totalTours={dayTours.length}
                                     currentPage={currentPage}
                                    paginate={paginate}
                                />
                            </TabPane>
                        )}
                        {uniqueCities.map((city, index) => (
                            activeTab === index && (
                                <TabPane key={index} tabId={index}>
                                    <Row>
                                        {citiesToToursMap[city]
                                            .slice(indexOfFirstTour, indexOfLastTour)
                                            .map(tour => (
                                                <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
                                                    <TourCard tour={tour} />
                                                </Col>
                                            ))}
                                    </Row>
                                    <Pagination
                                        toursPerPage={toursPerPage}
                                        totalTours={citiesToToursMap[city].length}
                                        currentPage={currentPage}
                                        paginate={paginate}
                                    />
                                </TabPane>
                            )
                        ))}
                    </TabContent>
                </section>
            </Container>
           {/*)}*/}
            <section>
                <Container>
                    <Row>
                        <SearchBar />
                    </Row>
                </Container>
            </section>

            <Newsletter />
        </>
    );
};






const Pagination = ({ toursPerPage, totalTours, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalTours / toursPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a onClick={() => paginate(1)} className="page-link common__span  ">
                        <i className="ri-asterisk"></i>
                    </a>
                </li>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a onClick={() => paginate(currentPage - 1)} className="page-link common__span">
                        <i className="ri-arrow-left-s-line"></i>
                    </a>
                </li>
                {pageNumbers.map((number, index) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active__page' : ''}`}>
                        {index === 0 ? (
                            <a onClick={() => paginate(number)} className={`page-link common__span `}>
                                {number}
                            </a>
                        ) : index === pageNumbers.length - 1 ? (
                            <a onClick={() => paginate(number)} className={`page-link common__span `}>
                                {number}
                            </a>
                        ) : (
                            <a onClick={() => paginate(number)} className={`page-link common__span`}>
                                {number}
                            </a>
                        )}
                    </li>
                ))}
                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                    <a onClick={() => paginate(currentPage + 1)} className="page-link common__span">
                        <i className="ri-arrow-right-s-line"></i>
                    </a>
                </li>
                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                    <a onClick={() => paginate(pageNumbers.length)} className="page-link common__span">
                        <i className="ri-asterisk"></i>
                    </a>
                </li>
            </ul>
        </nav>
    );
};




export default DayTour;
